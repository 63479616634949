import LayoutA5 from "../../components/layout-a5"
import React from "react"
import EnrollInfoDetail from '../../components/enroll-info-detail'

export default class A5EnrollInfoDetail extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    return(
      <LayoutA5 location={this.props.location} >
        <EnrollInfoDetail  color={color}/>
      </LayoutA5>
    )
  }
}